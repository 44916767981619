<template>
  <div>
    <app-page-detail page-title="Offer Detail" page-toolbar-title="Offer Detail">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @btn-save-action="updateData()"></action-button>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <card-expansion title="General" class="mb-3" :loading="loading">
            <v-row>
              <v-col cols="12" md="6">
                <app-data-line label="Doc. No." v-model="data_detail.header.document_no_"></app-data-line>
                <app-data-line label="Doc. Date" v-model="data_detail.header.document_date"></app-data-line>
                <app-data-line label="Doc. Version" v-model="data_detail.header.document_version"></app-data-line>
                <app-data-line label="Created By">
                  <app-user-info :username="data_detail.header.created_by_username">{{ data_detail.header.created_by_username }}</app-user-info>
                </app-data-line>
                <app-data-line label="Last Date" v-model="data_detail.header.created_date"></app-data-line>
                <app-data-line label="Last Modified" v-model="data_detail.header.last_modified"></app-data-line>
                <app-data-line label="Total Amount">{{ numberFormat(data_detail.total_amount, 2) }}</app-data-line>
              </v-col>
              <v-col cols="12" md="6">
                <app-data-line label="NAV Vendor No." v-model="data_detail.vendor.nav_vendor_no_"></app-data-line>
                <app-data-line label="Vendor Name">
                  <app-vendor-info :vendor-id="data_detail.vendor.id">{{ data_detail.vendor.name }}</app-vendor-info>
                </app-data-line>
                <app-data-line label="Vendor PIC">
                  <app-user-info :username="data_detail.vendor.username">{{ data_detail.vendor.pic_name }}</app-user-info>
                </app-data-line>
                <app-data-line label="Kota Asal" v-model="data_detail.vendor.regency_name"></app-data-line>
              </v-col>
            </v-row>
          </card-expansion>

          <card-expansion title="Lines" class="mb-3" :loading="loading" no-padding>
            <app-data-table ref="refDatatable" :uri="datatable.uri" :custom-params="datatable.customParams" :headers="datatable.headers" show-totals>
              <template v-slot:quantity="{ item }">
                {{ numberFormat(item.quantity, 2) }}
              </template>
              <template v-slot:unit_price="{ item }">
                {{ numberFormat(item.unit_price, 2) }}
              </template>
              <template v-slot:discount="{ item }">
                {{ numberFormat(item.discount, 2) }}
              </template>
              <template v-slot:amount="{ item }">
                {{ numberFormat(item.amount, 2) }}
              </template>
            </app-data-table>

            <v-divider class="mb-2"></v-divider>
            <v-row class="mb-3">
              <v-col cols="12">
                <div class="float-end d-block text-h5 px-3">
                  <v-row>
                    <v-col cols="12">
                      <strong><span class="mr-4">Total Amount:</span> <span>{{ numberFormat(data_detail.total_amount, 2) }}</span></strong>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </card-expansion>

          <card-expansion title="Attachments" class="mb-3" :loading="loading">
            <v-row>
              <v-col>
                <ol>
                  <li v-if="data_detail.header.attachment1_src">
                    <a :href="data_detail.header.attachment1_src" target="_blank">{{ ifNull(data_detail.header.attachment1_title, "-") }} <br>
                      {{ data_detail.header.attachment1_src }}
                    </a>
                  </li>
                  <li v-if="data_detail.header.attachment2_src">
                    <a :href="data_detail.header.attachment2_src" target="_blank">{{ ifNull(data_detail.header.attachment2_title, "-") }}</a>
                  </li>
                  <li v-if="data_detail.header.attachment3_src">
                    <a :href="data_detail.header.attachment3_src" target="_blank">{{ ifNull(data_detail.header.attachment3_title, "-") }}</a>
                  </li>
                  <li v-if="data_detail.header.attachment4_src">
                    <a :href="data_detail.header.attachment4_src" target="_blank">{{ ifNull(data_detail.header.attachment4_title, "-") }}</a>
                  </li>
                </ol>
              </v-col>
            </v-row>
          </card-expansion>

        </v-col>
        <v-col cols="12" md="3">
          <!-- Document Info -->
          <document-info :value="data_detail.header"></document-info>

          <!-- Comment  -->
          <app-comment :document_id="parseInt(data_detail.header.id)" :source_document="data_detail.header.source_document" :document_no_="data_detail.header.document_no_"></app-comment>
        </v-col>
      </v-row>

    </app-page-detail>
  </div>
</template>

<script>
import ActionButton from './components/ActionButton.vue';
import DocumentInfo from './components/DocumentInfo.vue';
export default {
  components: {
    ActionButton,
    DocumentInfo,
  },
  data() {
    return {
      dialog: null,
      loading: false,
      notFound: false,
      dtId: this.$route.query.id,
      data_detail: {
        "header": {},
        "details": [],
        "tender": {},
        "vendor": {},
        "total_amount": 0,
      },

      datatable: {
        uri: "tender/offer/details",
        headers: [
          { text: "Item No.", value: "item_no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "UOM", value: "uom", class: "text-no-wrap" },
          { text: "Quantity", value: "quantity", class: "text-no-wrap", cellClass: "text-end" },
          { text: "Unit Price", value: "unit_price", class: "text-no-wrap", cellClass: "text-end" },
          { text: "Discount", value: "discount", class: "text-no-wrap", cellClass: "text-end" },
          { text: "Amount", value: "amount", class: "text-no-wrap", cellClass: "text-end" },
        ],
        customParams: {
          tender_offer_header_id: this.$route.query.id,
        }
      },
      totalAmount: 0,
    };
  },
  watch: {
  },
  methods: {
    /**
     * updateData
     * Method untuk mengupdate data
     */
    refreshDetail() {
      this.getDetail(true);

      this.$refs.refDatatable.refresh();
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      const uri = "tender/offer/detail";
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", uri);


      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get(uri, config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.showLoadingOverlay(false);
          this.loading = false;
        })
        .catch((error) => {

          if (error.response.status == 404) {
            this.redirect404()
          }

          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.getDetail(true);
  },
};
</script>