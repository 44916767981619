<template>
  <div class="d-flex">
    <app-button @click="refreshDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>

    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableChangeStatusBtn" @click="showChangeStatusDialog()" title="Change Status" mdi-icon="mdi-file-arrow-left-right" :loading="loading_change_status"></app-button>
    <app-button :disabled="!enableChangeStatusBtn" @click="accept()" title="Accept" mdi-icon="mdi-check-circle-outline"></app-button>

    <app-dialog v-model="changeStatusDialog" title="Change Status" :max-width="500">
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-autocomplete label="Status" outlined hint="Select Status" :items="listStatus" item-text="status" item-value="status" v-model="formUpdateStatus.status">
                <template v-slot:selection="data">
                  <app-document-status v-model="data.item.status"></app-document-status>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        <app-document-status v-model="data.item.status"></app-document-status>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="d-block my-2">{{ data.item.desc }}</div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-textarea label="Reason" outlined hint="Input Reason" v-model="formUpdateStatus.reason"></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <template #actions>
        <v-btn color="primary" @click="changeStatus()">Change</v-btn>
      </template>
    </app-dialog>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      loading_change_status: false,
      data_detail: this.dataDetail,

      changeStatusDialog: false,
      listStatusHeader: [
        { text: '#', value: 'act', align: 'start', class: 'col-1', cellClass: 'col-1' },
        { text: 'Status', value: 'status', align: 'start', class: 'col-2', cellClass: 'col-2' },
        { text: 'Description', value: 'desc', align: 'start' },
      ],
      listStatus: [
        { desc: 'Penawaran dalam proses pembuatan', status: 'draft', },
        { desc: 'Penawaran telah diajukan', status: 'submitted', },
        { desc: 'Penawaran sedang dievaluasi', status: 'under-review', },
        { desc: 'Dokumen tidak lengkap', status: 'incomplete', },
        { desc: 'Klarifikasi tambahan diminta', status: 'clarification-requested', },
        { desc: 'Penawaran diterima', status: 'accepted', },
        { desc: 'Penawaran ditolak', status: 'rejected', },
        { desc: 'Penawaran gugur', status: 'disqualified', },
        { desc: 'Masuk dalam daftar pendek', status: 'shortlisted', },
        { desc: 'Penawaran ditarik', status: 'withdrawn', },
        { desc: 'Pemenang tender', status: 'awarded', },
        { desc: 'Kontrak telah ditandatangani', status: 'contract-signed', },
        { desc: 'Penawaran kedaluwarsa', status: 'expired', },
        { desc: 'Proses tender dibatalkan', status: 'cancelled', },
        { desc: 'Proses ditunda sementara', status: 'on-hold', },
      ],
      formUpdateStatus: {
        id: this.dataDetail.header.id,
        status: '',
        reason: '',
      },
    }
  },
  watch: {
    dataDetail() {
      this.data_detail = this.dataDetail;
      this.formUpdateStatus.id = this.dataDetail.header.id;
    }
  },
  computed: {
    user() {
      return (typeof this.dataDetail.user != 'undefined') ? this.dataDetail.user : {};
    },
    enableDetailBtn() {
      if (this.$route.name !== 'Purchasing.Tender.OfferDetail') {
        return true;
      }
      return false;
    },
    enableChangeStatusBtn() {
      return true;
    },
  },
  methods: {

    refreshDetail() {
      this.$emit('refresh-detail')
    },

    showChangeStatusDialog() {
      this.clearFormUpdateStatus();
      this.changeStatusDialog = true;
    },

    hideChangeStatusDialog() {
      this.changeStatusDialog = false;
    },

    onClickRow($event) {
      console.log($event);
    },

    clearFormUpdateStatus() {
      this.formUpdateStatus.status = '';
      this.formUpdateStatus.reason = '';
    },

    changeStatus() {
      let post = async () => {
        this.loading_change_status = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.formUpdateStatus.id);
        formData.append("status", this.formUpdateStatus.status);
        formData.append("reason", this.formUpdateStatus.reason);
        await this.$axios
          .post("tender/offer/set-status", formData)
          .then((res) => {
            this.loading_change_status = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();

            this.hideChangeStatusDialog();
          })
          .catch((error) => {
            this.loading_change_status = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Change Status Document to \"" + this.formUpdateStatus.status + "\"?";
      this.showConfirm("Confirm", msg, post);
    },

    accept() {
      let post = async () => {
        this.loading_change_status = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.formUpdateStatus.id);
        formData.append("status", this.formUpdateStatus.status);
        formData.append("reason", this.formUpdateStatus.reason);
        await this.$axios
          .post("tender/offer/set-status", formData)
          .then((res) => {
            this.loading_change_status = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();

            this.hideChangeStatusDialog();
          })
          .catch((error) => {
            this.loading_change_status = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Set Winner?";
      this.showConfirm("Confirm", msg, post);
    },

    updateData() {
      this.$emit("btn-save-action")
    },


  }
}
</script>