<template>
  <div>
    <card-expansion title="Document Info" mdi-icon="mdi-file" class="mb-3" :isMinimize="false" no-padding>
      <v-simple-table dense fixed-header>
        <template v-slot:default>
          <tbody>
            <tr>
              <th>ID:</th>
              <td>{{ internalValue.id }}</td>
            </tr>
            <tr>
              <th>Document No:</th>
              <td>{{ internalValue.document_no_ }}</td>
            </tr>
            <tr>
              <th>Created At:</th>
              <td>{{ internalValue.created_date }}</td>
            </tr>
            <tr>
              <th>Created By:</th>
              <td>
                <user-info :username="internalValue.created_by_username">{{ internalValue.created_by_username }}</user-info>
              </td>
            </tr>
            <tr>
              <th>Last Updated:</th>
              <td>
                <app-log-data-dialog module_name="tenderoffer" :id="internalValue.id">
                  <a href="javascript:;">{{ internalValue.last_modified }}</a>
                </app-log-data-dialog>
              </td>
            </tr>
            <tr>
              <th>Status:</th>
              <td><app-document-status v-model="internalValue.status" @click="dialog = true"></app-document-status></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </card-expansion>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>Reason</v-card-title>
        <v-card-text>
          <p>{{ internalValue.reason }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserInfo from '@/components/user/UserInfo.vue';
export default {
  props: ["value"],
  components: {
    UserInfo,
  },
  data() {
    return {
      internalValue: this.value,
      dialog: false,
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.internalValue = val
      }
    }
  }
}
</script>